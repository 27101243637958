<template>
  <section class="navbar-spacer">
    <div class="container content has-text-left mt-6 px-4">
      <h4 class="title is-size-3 is-size-5-touch">Impressum</h4>
      <h4 class="title is-size-4 is-size-5-touch mt-4">
        Angaben gem&auml;&szlig; &sect; 5 TMG
      </h4>
      <p>
        Straight and Curved UG (haftungsbeschr&auml;nkt) in Liquidation<br />
        Schwetzinger Stra&szlig;e 140<br />
        68165 Mannheim
      </p>

      <p>
        Handelsregister: HRB 740956<br />
        Registergericht: Amtsgericht Mannheim
      </p>

      <p>
        <strong>Vertreten durch:</strong><br />
        Tristan Emig &amp; Anton Ochel
      </p>

      <h4 class="title is-size-4 is-size-5-touch mt-4">Kontakt</h4>
      <p>
        Telefon: 0621 49637311<br />
        E-Mail: contact@straightandcurved.com
      </p>

      <h4 class="title is-size-4 is-size-5-touch mt-4">Umsatzsteuer-ID</h4>
      <p>
        Umsatzsteuer-Identifikationsnummer gem&auml;&szlig; &sect; 27 a
        Umsatzsteuergesetz:<br />
        DE345711461
      </p>

      <h4 class="title is-size-4 is-size-5-touch mt-4">Gewerbeanmeldung</h4>
      <p>
        Die Gewerbeanmeldung nach &sect; 15 Abs. 1 GewO wurde am 06.09.2021
        durch Stadt Mannheim erteilt.
      </p>

      <h4 class="title is-size-4 is-size-5-touch mt-4">EU-Streitschlichtung</h4>
      <p>
        Die Europ&auml;ische Kommission stellt eine Plattform zur
        Online-Streitbeilegung (OS) bereit:
        <a
          href="https://ec.europa.eu/consumers/odr/"
          target="_blank"
          rel="noopener noreferrer nofollow"
          >https://ec.europa.eu/consumers/odr/</a
        >.<br />
        Unsere E-Mail-Adresse finden Sie oben im Impressum.
      </p>

      <h4 class="title is-size-4 is-size-5-touch mt-4">
        Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle
      </h4>
      <p>
        Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren
        vor einer Verbraucherschlichtungsstelle teilzunehmen.
      </p>

      <p>
        Quelle:
        <a href="https://www.e-recht24.de" rel="nofollow"
          >https://www.e-recht24.de</a
        >
      </p>
    </div>
  </section>
</template>

<script>
export default {
  name: "Imprint",
};
</script>


<style scoped>
</style>
